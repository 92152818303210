import { render } from "./DashboardSpaceSimulations.vue?vue&type=template&id=0648e842&scoped=true"
import script from "./DashboardSpaceSimulations.vue?vue&type=script&lang=js"
export * from "./DashboardSpaceSimulations.vue?vue&type=script&lang=js"

import "./DashboardSpaceSimulations.vue?vue&type=style&index=0&id=0648e842&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-0648e842"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0648e842"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0648e842', script)) {
    api.reload('0648e842', script)
  }
  
  module.hot.accept("./DashboardSpaceSimulations.vue?vue&type=template&id=0648e842&scoped=true", () => {
    api.rerender('0648e842', render)
  })

}

script.__file = "src/components/dashboard/DashboardSpaceSimulations.vue"

export default script